import { Weather } from '../../interfaces';

export const weatherTemplate: Weather = {
  externalLastUpdate: '',
  degree: 0,
  isDay: 1,
  conditionText: '',
  conditionCode: '',
  id: -1,
  createdAt: new Date(),
  updatedAt: new Date(),
  humidity: 0,
  windK: 0,
  windM: 0,
};
