import { Config } from '../../interfaces';

export const configTemplate: Config = {
  location: '',
  isCelsius: true,
  customTitle: 'Flame',
  pinAppsByDefault: true,
  pinCategoriesByDefault: true,
  hideHeader: false,
  useOrdering: 'createdAt',
  appsSameTab: false,
  bookmarksSameTab: false,
  searchSameTab: false,
  hideApps: false,
  hideCategories: false,
  hideSearch: false,
  defaultSearchProvider: 'l',
  dockerApps: false,
  dockerHost: 'localhost',
  unpinStoppedApps: false,
  useAmericanDate: false,
  disableAutofocus: false,
  greetingsSchema: '晚上好！;下午好！;早上好！;晚安！',
  daySchema: '星期天;星期一;星期二;星期三;星期四;星期五;星期六',
  monthSchema:
    '1月;2月;3月;4月;5月;6月;7月;8月;9月;10月;11月;12月',
  showTime: false,
  defaultTheme: 'tron',
  isKilometer: true,
  weatherData: 'humidity',
  hideDate: false,
};
