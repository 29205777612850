import { Fragment } from 'react';
import { SettingsHeadline } from '../../UI';
import classes from './AppDetails.module.css';
import { AuthForm } from './AuthForm/AuthForm';

export const AppDetails = (): JSX.Element => {
  return (
    <Fragment>
      <SettingsHeadline text="认证" />
      <AuthForm />

      <hr className={classes.separator} />
      <div>
        <SettingsHeadline text="GitHub Repository" />
        <p className={classes.text}>
          Modified from{' '}
          <a
            href="https://github.com/pawelmalak/flame"
            target="_blank"
            rel="noreferrer"
          >https://github.com/pawelmalak/flame</a>
        </p>
        <p className={classes.text}>
        by <a href="https://soulteary.com/">@soulteary</a>, 2022.01.05
        </p>

      </div>
    </Fragment>
  );
};
