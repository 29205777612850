import { NavLink, Link, Switch, Route } from 'react-router-dom';

// Redux
import { useSelector } from 'react-redux';
import { State } from '../../store/reducers';

// Typescript
import { Route as SettingsRoute } from '../../interfaces';

// CSS
import classes from './Settings.module.css';

// Components
import { Themer } from './Themer/Themer';
import { WeatherSettings } from './WeatherSettings/WeatherSettings';
import { UISettings } from './UISettings/UISettings';
import { AppDetails } from './AppDetails/AppDetails';
import { SearchSettings } from './SearchSettings/SearchSettings';
import { DockerSettings } from './DockerSettings/DockerSettings';
import { ProtectedRoute } from '../Routing/ProtectedRoute';

// UI
import { Container, Headline } from '../UI';

// Data
import { routes } from './settings.json';

export const Settings = (): JSX.Element => {
  const { isAuthenticated } = useSelector((state: State) => state.auth);

  const tabs = isAuthenticated ? routes : routes.filter((r) => !r.authRequired);

  const getZhLabel = (name :string) :string => {
    const OptionZhNames = [
      { name: "Theme", value: "主题"},
      { name: "Weather", value: "天气"},
      { name: "Search", value: "搜索"},
      { name: "Interface", value: "界面"},
      { name: "Docker", value: "Docker"},
      { name: "App", value: "应用"},
    ];

    const found = OptionZhNames.filter((option) => {
      return option.name === name;
    });

    if (found.length){
      return found[0].value;
    }
    return name;
  }

  return (
    <Container>
      <Headline title="应用设置" subtitle={<Link to="/">返回</Link>} />
      <div className={classes.Settings}>
        {/* NAVIGATION MENU */}
        <nav className={classes.SettingsNav}>
          {tabs.map(({ name, dest }: SettingsRoute, idx) => (
              <NavLink
              className={classes.SettingsNavLink}
              activeClassName={classes.SettingsNavLinkActive}
              exact
              to={dest}
              key={idx}
            >{ getZhLabel(name) }</NavLink>
          ))}
        </nav>

        {/* ROUTES */}
        <section className={classes.SettingsContent}>
          <Switch>
            <Route exact path="/settings" component={Themer} />
            <ProtectedRoute
              path="/settings/weather"
              component={WeatherSettings}
            />
            <ProtectedRoute
              path="/settings/search"
              component={SearchSettings}
            />
            <ProtectedRoute path="/settings/interface" component={UISettings} />
            <ProtectedRoute
              path="/settings/docker"
              component={DockerSettings}
            />
            <Route path="/settings/app" component={AppDetails} />
          </Switch>
        </section>
      </div>
    </Container>
  );
};
