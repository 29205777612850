import { parseTime } from '../../../../utility';

export const getDateTime = (): string => {
  const days = localStorage.getItem('daySchema')?.split(';') || [
    '星期天',
    '星期一',
    '星期二',
    '星期三',
    '星期四',
    '星期五',
    '星期六',
  ];

  const months = localStorage.getItem('monthSchema')?.split(';') || [
    '1月',
    '2月',
    '3月',
    '4月',
    '5月',
    '6月',
    '7月',
    '8月',
    '9月',
    '10月',
    '11月',
    '12月',
  ];

  const now = new Date();

  const showTime = localStorage.showTime === 'true';
  const hideDate = localStorage.hideDate === 'true';

  // Date
  let dateEl = '';


  if (!hideDate) {
    dateEl = `${now.getFullYear()}年${months[now.getMonth()]}${now.getDate()}日 ${days[now.getDay()]}`;
  }

  // Time
  const p = parseTime;
  let timeEl = '';

  if (showTime) {
    const time = `${p(now.getHours())}:${p(now.getMinutes())}:${p(
      now.getSeconds()
    )}`;

    timeEl = time;
  }

  // Separator
  let separator = '';

  if (!hideDate && showTime) {
    separator = ' - ';
  }

  // Output
  return `${dateEl}${separator}${timeEl}`;
};
