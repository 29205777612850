import classes from './AppGrid.module.css';
import { Link } from 'react-router-dom';
import { App } from '../../../interfaces/App';

import { AppCard } from '../AppCard/AppCard';
import { Message } from '../../UI';

interface Props {
  apps: App[];
  totalApps?: number;
  searching: boolean;
}

export const AppGrid = (props: Props): JSX.Element => {
  let apps: JSX.Element;

  if (props.searching || props.apps.length) {
    if (!props.apps.length) {
      apps = <Message>没有找到匹配的应用</Message>;
    } else {
      apps = (
        <div className={classes.AppGrid}>
          {props.apps.map((app: App): JSX.Element => {
            return <AppCard key={app.id} app={app} />;
          })}
        </div>
      );
    }
  } else {
    if (props.totalApps) {
      apps = (
        <Message>
          暂时没有被置顶的应用。可以从 {' '}
          <Link to="/applications">/applications</Link> 菜单中挑选应用置顶。
        </Message>
      );
    } else {
      apps = (
        <Message>
          你还没有添加任何应用。可以从{' '}
          <Link to="/applications">/applications</Link> 菜单中添加新的应用。
        </Message>
      );
    }
  }

  return apps;
};
