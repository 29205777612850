import { useState, useEffect, ChangeEvent, FormEvent } from 'react';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { State } from '../../../store/reducers';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../../store';

// Typescript
import { OtherSettingsForm } from '../../../interfaces';

// UI
import { InputGroup, Button, SettingsHeadline } from '../../UI';

// Utils
import { otherSettingsTemplate, inputHandler } from '../../../utility';

export const UISettings = (): JSX.Element => {
  const {
    config: { loading, config },
    bookmarks: { categories },
  } = useSelector((state: State) => state);

  const dispatch = useDispatch();
  const { updateConfig, sortApps, sortCategories, sortBookmarks } =
    bindActionCreators(actionCreators, dispatch);

  // Initial state
  const [formData, setFormData] = useState<OtherSettingsForm>(
    otherSettingsTemplate
  );

  // Get config
  useEffect(() => {
    setFormData({
      ...config,
    });
  }, [loading]);

  // Form handler
  const formSubmitHandler = async (e: FormEvent) => {
    e.preventDefault();

    // Save settings
    await updateConfig(formData);

    // Update local page title
    document.title = formData.customTitle;

    // Sort entities with new settings
    if (formData.useOrdering !== config.useOrdering) {
      sortApps();
      sortCategories();

      for (let { id } of categories) {
        sortBookmarks(id);
      }
    }
  };

  // Input handler
  const inputChangeHandler = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    options?: { isNumber?: boolean; isBool?: boolean }
  ) => {
    inputHandler<OtherSettingsForm>({
      e,
      options,
      setStateHandler: setFormData,
      state: formData,
    });
  };

  return (
    <form onSubmit={(e) => formSubmitHandler(e)}>
      {/* === OTHER OPTIONS === */}
      <SettingsHeadline text="杂项" />
      {/* PAGE TITLE */}
      <InputGroup>
        <label htmlFor="customTitle">自定义标题</label>
        <input
          type="text"
          id="customTitle"
          name="customTitle"
          placeholder="Flame"
          value={formData.customTitle}
          onChange={(e) => inputChangeHandler(e)}
        />
      </InputGroup>

      {/* === HEADER OPTIONS === */}
      <SettingsHeadline text="应用顶部设置" />
      {/* HIDE HEADER */}
      <InputGroup>
        <label htmlFor="hideHeader">
          是否隐藏标题栏 (问候语和天气部分)
        </label>
        <select
          id="hideHeader"
          name="hideHeader"
          value={formData.hideHeader ? 1 : 0}
          onChange={(e) => inputChangeHandler(e, { isBool: true })}
        >
          <option value={1}>隐藏</option>
          <option value={0}>展示</option>
        </select>
      </InputGroup>

      {/* HIDE DATE */}
      <InputGroup>
        <label htmlFor="hideDate">是否展示日期</label>
        <select
          id="hideDate"
          name="hideDate"
          value={formData.hideDate ? 1 : 0}
          onChange={(e) => inputChangeHandler(e, { isBool: true })}
        >
          <option value={1}>隐藏</option>
          <option value={0}>展示</option>
        </select>
      </InputGroup>

      {/* HIDE TIME */}
      <InputGroup>
        <label htmlFor="showTime">是否展示时间</label>
        <select
          id="showTime"
          name="showTime"
          value={formData.showTime ? 1 : 0}
          onChange={(e) => inputChangeHandler(e, { isBool: true })}
        >
          <option value={0}>隐藏</option>
          <option value={1}>展示</option>
        </select>
      </InputGroup>

      {/* CUSTOM GREETINGS */}
      <InputGroup>
        <label htmlFor="greetingsSchema">自定义问候语</label>
        <input
          type="text"
          id="greetingsSchema"
          name="greetingsSchema"
          placeholder="Good day;Hi;Bye!"
          value={formData.greetingsSchema}
          onChange={(e) => inputChangeHandler(e)}
        />
        <span>
          问候语必须以分号分割，且必须存在四个元素
        </span>
      </InputGroup>

      {/* CUSTOM DAYS */}
      <InputGroup>
        <label htmlFor="daySchema">自定义星期名称</label>
        <input
          type="text"
          id="daySchema"
          name="daySchema"
          placeholder="星期日;星期六;星期四"
          value={formData.daySchema}
          onChange={(e) => inputChangeHandler(e)}
        />
        <span>名称必须以分号分割</span>
      </InputGroup>

      {/* CUSTOM MONTHS */}
      <InputGroup>
        <label htmlFor="monthSchema">自定义月份名称</label>
        <input
          type="text"
          id="monthSchema"
          name="monthSchema"
          placeholder="1月;2月;3月"
          value={formData.monthSchema}
          onChange={(e) => inputChangeHandler(e)}
        />
        <span>名称必须以分号分割</span>
      </InputGroup>

      {/* === BEAHVIOR OPTIONS === */}
      <SettingsHeadline text="应用默认行为" />
      {/* PIN APPS */}
      <InputGroup>
        <label htmlFor="pinAppsByDefault">
          默认置顶新创建的应用
        </label>
        <select
          id="pinAppsByDefault"
          name="pinAppsByDefault"
          value={formData.pinAppsByDefault ? 1 : 0}
          onChange={(e) => inputChangeHandler(e, { isBool: true })}
        >
          <option value={1}>置顶</option>
          <option value={0}>不置顶</option>
        </select>
      </InputGroup>

      {/* PIN CATEGORIES */}
      <InputGroup>
        <label htmlFor="pinCategoriesByDefault">
          默认置顶新的分类
        </label>
        <select
          id="pinCategoriesByDefault"
          name="pinCategoriesByDefault"
          value={formData.pinCategoriesByDefault ? 1 : 0}
          onChange={(e) => inputChangeHandler(e, { isBool: true })}
        >
          <option value={1}>置顶</option>
          <option value={0}>不置顶</option>
        </select>
      </InputGroup>

      {/* SORT TYPE */}
      <InputGroup>
        <label htmlFor="useOrdering">排序方式</label>
        <select
          id="useOrdering"
          name="useOrdering"
          value={formData.useOrdering}
          onChange={(e) => inputChangeHandler(e)}
        >
          <option value="createdAt">按照创建日期排序</option>
          <option value="name">按照首字母排序</option>
          <option value="orderId">自定义排序</option>
        </select>
      </InputGroup>

      {/* APPS OPPENING */}
      <InputGroup>
        <label htmlFor="appsSameTab">在相同的页面打开新的应用</label>
        <select
          id="appsSameTab"
          name="appsSameTab"
          value={formData.appsSameTab ? 1 : 0}
          onChange={(e) => inputChangeHandler(e, { isBool: true })}
        >
          <option value={1}>是</option>
          <option value={0}>否</option>
        </select>
      </InputGroup>

      {/* BOOKMARKS OPPENING */}
      <InputGroup>
        <label htmlFor="bookmarksSameTab">在相同的页面打开新的书签</label>
        <select
          id="bookmarksSameTab"
          name="bookmarksSameTab"
          value={formData.bookmarksSameTab ? 1 : 0}
          onChange={(e) => inputChangeHandler(e, { isBool: true })}
        >
          <option value={1}>是</option>
          <option value={0}>否</option>
        </select>
      </InputGroup>

      {/* === MODULES OPTIONS === */}
      <SettingsHeadline text="模块" />
      {/* HIDE APPS */}
      <InputGroup>
        <label htmlFor="hideApps">隐藏应用</label>
        <select
          id="hideApps"
          name="hideApps"
          value={formData.hideApps ? 1 : 0}
          onChange={(e) => inputChangeHandler(e, { isBool: true })}
        >
          <option value={1}>是</option>
          <option value={0}>否</option>
        </select>
      </InputGroup>

      {/* HIDE CATEGORIES */}
      <InputGroup>
        <label htmlFor="hideCategories">隐藏分类</label>
        <select
          id="hideCategories"
          name="hideCategories"
          value={formData.hideCategories ? 1 : 0}
          onChange={(e) => inputChangeHandler(e, { isBool: true })}
        >
          <option value={1}>是</option>
          <option value={0}>否</option>
        </select>
      </InputGroup>

      <Button>保存配置</Button>
    </form>
  );
};
