import {
  DockerSettingsForm,
  OtherSettingsForm,
  SearchForm,
  ThemeSettingsForm,
  WeatherForm,
} from '../../interfaces';

export const otherSettingsTemplate: OtherSettingsForm = {
  customTitle: document.title,
  pinAppsByDefault: true,
  pinCategoriesByDefault: true,
  hideHeader: false,
  hideApps: false,
  hideCategories: false,
  useOrdering: 'createdAt',
  appsSameTab: false,
  bookmarksSameTab: false,
  useAmericanDate: false,
  greetingsSchema: '晚上好！;下午好！;早上好！;晚安！',
  daySchema: '星期天;星期一;星期二;星期三;星期四;星期五;星期六',
  monthSchema:
    '1月;2月;3月;4月;5月;6月;7月;8月;9月;10月;11月;12月',
  showTime: false,
  hideDate: false,
};

export const weatherSettingsTemplate: WeatherForm = {
  location: '',
  isCelsius: true,
  weatherData: 'humidity',
};

export const searchSettingsTemplate: SearchForm = {
  hideSearch: false,
  searchSameTab: false,
  defaultSearchProvider: 'l',
  disableAutofocus: false,
};

export const dockerSettingsTemplate: DockerSettingsForm = {
  dockerApps: true,
  dockerHost: 'localhost',
  unpinStoppedApps: true,
};

export const themeSettingsTemplate: ThemeSettingsForm = {
  defaultTheme: 'tron',
};
